import React from 'react';
import Block from './HomeSection-About-Blocks'
import { API_IMAGE_PATH } from './_Config'

export default ({ content }) => (
  <div className='home-about'>
    <div className='home-about-image' style={{backgroundImage: 'url(' + API_IMAGE_PATH + content[5].content + ')'}}>
      <div className='home-about-image-content'>
        <h3>70+ Years of Experience on the Eastern Shore</h3>
      </div>
    </div>
    <div className='home-about-container'>
      <div className='hac-blocks'>
        <Block icon='fas fa-globe' header='More Options' text="We've got access to the biggest names in the business, so that means you do too." />
        <Block icon='far fa-thumbs-up' header='Your Best Interest' text="Our goal is connect you with the best insurance to satisfy your needs - regardless of who it's with. Because of that, you can rest assured that your best interests are given priority." />
        <Block icon='far fa-lightbulb' header='Latest Innovations' text="Our people keep up with the latest trends and offerings in the industry. You'll have full access to the latest innovative products available on the market." />
      </div>
    </div>
  </div>
)