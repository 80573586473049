import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import StaticLayout from './_Components/Layout-Static'
import AutomaticBanner from './_Components/AutomaticBanner'
import QuoteBar from './_Components/QuoteBar'
import HomeSectionIndividual from './_Components/HomeSection-Individual'
import HomeSectionBusiness from './_Components/HomeSection-Business'
import HomeSectionAbout from './_Components/HomeSection-About'

const Home = React.memo(({ content }) => {

	const [ scrollPos, setScrollPos ] = useState(0)

	useEffect(() => {
		window.addEventListener('scroll', handleScroll())
		return () => {
			window.removeEventListener('scroll', handleScroll())
		}
	})

	const handleScroll = (e) => {
		window.onscroll = function(){
			var st = window.pageYOffset
			setScrollPos(st)
		}
	}

	return (
		<div className='page-content'>
			<AutomaticBanner slides={content[0].content} scroll={scrollPos} />
			<QuoteBar />
			<HomeSectionIndividual content={content} scroll={scrollPos} />
			<HomeSectionBusiness content={content} scroll={scrollPos} />
			<HomeSectionAbout content={content}/>
		</div>
	)
})

Home.propTypes = {
	content: PropTypes.array
}

export default () => (
	<StaticLayout>
		<Home />
	</StaticLayout>
)