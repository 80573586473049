import React, { useState, useEffect, useCallback } from 'react';
import { API_IMAGE_PATH } from './_Config'

const Module = React.memo(({ scroll, content }) => {
	
	const [ background, setBackground ] = useState(content[2].content[0])
	const [ initial, setInitial ] = useState(true)
	const [ slides, setSlides ] = useState(content[2].content) // eslint-disable-line

	const advanceSlides = useCallback((index, cancel = false) => {
		if (slides.length > 1) {
			const advance = setTimeout(() => {
				setBackground(slides[index])
				if (index + 1 === slides.length) advanceSlides(0)
				else advanceSlides(index + 1)
			}, 3000)

			if (cancel) clearTimeout(advance)
		}
	}, [slides])

	useEffect(() => {
		if (initial) {
			advanceSlides(1)
			setInitial(false)
		}
		return () => {
			advanceSlides(1, true)
		}
	}, [initial, advanceSlides])

	return (
		<div className='page-top' style={{backgroundImage: 'url(' + API_IMAGE_PATH + background + ')'}}>
			<div className='pt-content'>
				<div className='pt-container'>
					<div className='ptc-text-container'>
						<h1 style={{marginTop: scroll / 3 + 'px'}}>{content[3].content}</h1>
					</div>
				</div>
			</div>
			<div style={{display: 'none'}}>
				{slides.map((img, i) => {
					return <img key={i} src={API_IMAGE_PATH + img} alt={'picture of community in Eastern Shore, MD - ' + img}/>
				})}
			</div>
		</div>
	)
})

export default Module