import React from 'react'
import PropTypes from 'prop-types'
import Layout from './_Components/Layout-Static'
import Banner from './_Components/PageBanner'

const Payments = React.memo(({ content }) => {
	return (
		<div className='page-content page-default'>
			<Banner content={content} />
			<div className='page-default-container'>
				<p className='pm-breadcrumbs' dangerouslySetInnerHTML={{ __html: content[1].content }} />
				<h2 className='pd-title'>{content[0].content}</h2>
				<div className='user-text' dangerouslySetInnerHTML={{ __html: content[4].content}} />
				<table className='payments-table' dangerouslySetInnerHTML={{ __html: content[5].content}} />
		  </div>
		</div>
	)
})

Payments.propTypes = {
	content: PropTypes.array
}

export default () => (
	<Layout>
		<Payments />
	</Layout>
)