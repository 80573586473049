import General from './General.json'

//================
//= Util for name formatting
//================

function nameToInputName(name) {
	const clean = name.replace(/[^a-zA-Z0-9 ]/g, '')
	var inputName = clean.replace(/ /gm, "_").toLowerCase()
	if (inputName.charAt(inputName.length) === '_') {
		inputName = inputName.slice(0, -1)
	}
	return inputName
}

//================
// Passing form data includes into data
//================

// const data = [
// 	Personal,
// 	Vehicle,
// 	Vehicle,
// 	Driver,
// 	Driver,
// 	Coverage,
// 	PriorPolicy,
// 	Additional
// ]

//================
// Setting initial variables
//================

const createObject = data => {
	var id = 0
	var view = []
	var state = []

	const setObject = (node, id) => {
		var temp = {
			type: node.type,
			name: node.name,
			required: node.required ? node.required : false,
			inputName: id + '_' + nameToInputName(node.name)
		}
		if (node.options) temp.options = node.options
		if (node.description) temp.description = node.description
		if (node.display) temp.display = node.display
		if (node.width) temp.width = node.width
		if (node.margin) temp.margin = node.margin
		if (node.text) temp.text = node.text
		if (node.checked !== undefined) temp.checked = node.checked
		return temp
	}

	data.forEach((section, i) => {
		let temp = []
		section.forEach((group, j) => {
			temp.push(setObject(data[i][j], id))
			state[id + '_' + nameToInputName(data[i][j].name)] = ''
			id++
		})
		view.push(temp)
	})
	return {view, state}
}

//================
// Setting returned object
//================

// const Form = {
// 	FormState: state,
// 	PersonalInputs: view[0],
// 	VehicleInputs: view[1],
// 	VehicleInputs2: view[2],
// 	DriverInputs: view[3],
// 	DriverInputs2: view[4],
// 	CoverageInputs: view[5],
// 	PriorPolicyInputs: view[6],
// 	AdditionalInputs: view[7]
// }

export default function() {
	const data = [General]
	const res = createObject(data)
	return {
		State: res.state, Inputs: res.view[0]
	}
}