import React from 'react'
import GoogleMapReact from 'google-map-react'

const GoogleMap = React.memo(({ center, zoom }) => {

  return (
    <div className='map'>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyBH68yZYeOYRpZD1-Fjtf1924y2e8EN5_A' }}
        defaultCenter={{lat: 39, lng: -76.076}}
        defaultZoom={9}>
        <i class="fal fa-map-marker"
          lat={39.220070} 
          lng={-76.077940} />
        <i class="fal fa-map-marker"
          lat={38.777360} 
          lng={-76.075260} />
      </GoogleMapReact>
    </div>
  )
})

export default GoogleMap