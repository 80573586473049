import React from 'react';
import { API_IMAGE_PATH } from './_Config'

export default () => (
  <div className='hc-logos'>
    <a href='/' className='desktop' title=''>
      <img src={API_IMAGE_PATH + 'bgv-icon.png'} alt='BGV Logo' />
      <span><strong>Phone:</strong> 410-822-2400<br /><strong>Address:</strong> 506 Idlewild Avenue<br />Easton, MD 21601</span>
    </a>
    <a href='/' title=''>
      <img src={API_IMAGE_PATH + 'fleetwood-logo.png'} alt='Fleetwood Logo' />
      <span><strong>Phone:</strong> 410-778-2260<br /><strong>Address:</strong> 100 Talbot Blvd.<br />Chestertown, MD 21620</span>
  	</a>
    <a href='http://dockmastersinsurance.com/' target='_blank' rel="noopener noreferrer" className='desktop' title=''>
    	<img src={API_IMAGE_PATH + 'dockmasters-logo-168.png'} alt='Dockmasters Logo' />
      <span><strong>Phone:</strong> 410-778-2260<br /><strong>Address:</strong> 100 Talbot Blvd.<br />Chestertown, MD 21620</span>
    </a>
    <div className='mobile'>
      <a href='/'>
        <img src={API_IMAGE_PATH + 'bgv-icon.png'} alt='BGV Logo' /></a>
      <a href='http://dockmastersinsurance.com/' target='_blank' rel="noopener noreferrer">
        <img src={API_IMAGE_PATH + 'dockmasters-logo-168.png'} alt='Dockmasters Logo' /></a>
    </div>
  </div>
)