import React from 'react';

export default () => {
  return (
    <div className='desktop-nav'>
    	<div className='desktop-nav-container'>
    		<div className='dnc'>
    			<a className='dnc-label' href='/personal'>Personal</a>
    			<div className='desktop-nav-sub'>
    				<div className='desktop-nav-sub-container'>
    					<a href='/personal/health'>Health</a>
              <a href='/personal/auto'>Auto</a>
              <a href='/personal/homeowners'>Homeowners</a>
              <a href='/personal/renters'>Renters</a>
              <a href='/personal/rv'>RV</a>
    				</div>
    			</div>
      	</div>
      	<div className='dnc'>
      		<a className='dnc-label' href='/business'>Business</a>
					<div className='desktop-nav-sub'>
    				<div className='desktop-nav-sub-container'>
    					<a href='/business/hunting'>Hunting Insurance</a>
    				</div>
    			</div>
      	</div>
      	<div className='dnc'>
      		<a className='dnc-label' href='/marine'>Marine</a>
      	</div>
      	<div className='dnc'>
      		<a className='dnc-label' href='/agriculture'>Agriculture</a>
      	</div>
        <div className='dnc'>
          <a className='dnc-label' href='/high-net-worth'>High Net Worth</a>
        </div>
      	<div className='dnc'>
      		<a className='dnc-label' href='/support/payments-and-claims'>Claims and Payments</a>
      	</div>
      	<a className='request-quote' href='/quote'>Request a Quote</a>
      </div>
    </div>
  )
}