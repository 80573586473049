import React, { useState, useEffect } from 'react'

import Input from './_FormElements/Input'
import Select from './_FormElements/Select'
import Checkbox from './_FormElements/Checkbox'
import Textarea from './_FormElements/Textarea'

const Form = React.memo((props) => {

	const [ sectionClass, setSectionClass ] = useState('quote-form-section')
	const [ clicked, setClicked ] = useState(0)
	const section = React.createRef()

	//===================================
	//= Effect for displaying section on page scroll
	//===================================

	useEffect(() => {
		const offset = section.current.offsetTop
		const windowH = window.innerHeight

		if (props.scroll !== undefined) {
			if (props.scroll > offset - windowH + (windowH / 3)) {
				setSectionClass('quote-form-section quote-form-section-show')
			}
		} else {
			setSectionClass('quote-form-section quote-form-section-show')
		}
	}, [section, props.scroll])

	//===================================
	//= Generate form elements based on inputs
	//===================================

	const generateFormComponent = (input, index = null) => {
		switch(input.type) {
			case 'select':
				return <Select key={index} {...input} value={props.inputs[input.inputName]} updateValue={props.updateValue} />
			case 'checkbox':
				return <Checkbox key={index} {...input} value={props.inputs[input.inputName]} updateValue={props.updateValue} />
			case 'textarea':
				return <Textarea key={index} {...input} value={props.inputs[input.inputName]} updateValue={props.updateValue} />
			default:
				switch(input.inputName) {
					case '2_first_name':
						return <Input key={index} {...input} value={props.first ? props.first : ''} updateValue={props.updateValue} />
					case '3_last_name':
						return <Input key={index} {...input} value={props.last ? props.last : ''} updateValue={props.updateValue} />
					default:
						return <Input key={index} {...input} value={props.inputs[input.inputName]} updateValue={props.updateValue} />
				}
		}
	}
	
	const inputComponents = (inputs) => {
		return inputs.map((input, index) => {
			return generateFormComponent(input, index)
		})
	}

	//===================================
	//= Return
	//===================================
	
	return (
		<div ref={section} className={sectionClass}>
			<h2>{props.name}</h2>
			{inputComponents(props.inputs)}

			{props.multiple && props.multiple.map((section, index) => {
				return <div key={index} className='form-additional-section' id={index} style={{display: (clicked >= index + 1) ? 'flex' : 'none'}} >
					{inputComponents(section)}
				</div>
			})}

			{props.multiple && <p className='form-additional-button' onClick={() => setClicked(clicked + 1)} style={{display: (clicked > props.multiple.length - 1) ? 'none' : 'block'}}>
				{props.multipleLabel}
				<i className="fas fa-plus-circle"></i>
			</p>}
		</div>
	)
})

export default Form