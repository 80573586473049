import React, { useState, useEffect } from 'react';
import { API_IMAGE_PATH } from './_Config'

const Sidebar = React.memo(({ scroll, content, text, url }) => {

	const [ top, setTop ] = useState(0)
	const sidebarRef = React.createRef()

	useEffect(() => {
		const offset = sidebarRef.current.offsetTop
		setTop(offset)
	}, [sidebarRef])

	return (
		<div ref={sidebarRef} className='page-sidebar'>
			<div className={scroll > top - 40 ? 'page-side-blocks psb-fixed' : 'page-side-blocks'}>
				<div className='psb'>
					<p>Give us a call today to discuss your {text} options:</p>
					<a href='tel:4107782260' className='phone'><span>Chestertown Location:</span>410-778-2260</a>
					<a href='tel:4108222400' className='phone'><span>Easton Location:</span>410-822-2400</a>
				</div>
				<div className='psb'>
					<p>Get {content[5].content} quote now!</p>
					<a className='button' href={(text === 'marine insurance') ? 'http://dockmastersinsurance.com/' : '/quote/' + url + '/null/null'}>Get Quote
						<i className='fas fa-long-arrow-alt-right'></i>
					</a>
				</div>
				{text === 'marine insurance' &&
					<img src={API_IMAGE_PATH + 'dockmasters-logo-168.png'} alt='Dockmasters Insurance logo' />
				}
			</div>
		</div>
	)
})

export default Sidebar