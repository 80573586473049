import React from 'react'
import PropTypes from 'prop-types'
import StaticLayout from './_Components/Layout-Static'

const Blog = React.memo(({ posts }) => {

	return (
		<div className='page-content'>
			<div className='posts'>
				<h1>Blog</h1>
				{posts.map((p,i) => {
					return <div className='event' key={i}>
						<p>{p.name}</p>
					</div>
				})}
			</div>
		</div>
	)
})

Blog.propTypes = {
	posts: PropTypes.array
}

export default () => (
	<StaticLayout>
		<Blog />
	</StaticLayout>
)