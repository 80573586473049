import React, { useState, useEffect } from 'react'
import Layout from './_Components/Layout-Static'
import Banner from './_Components/PageBanner'
import QuoteBar from './_Components/QuoteBar'
import Content from './_Components/PageContent-Insurance'
import Sidebar from './_Components/PageSidebar'

function Page(props) {

	const [ scrollPos, setScrollPos ] = useState(0)

	useEffect(() => {
		window.addEventListener('scroll', handleScroll())
		return () => {
			window.removeEventListener('scroll', handleScroll())
		}
	})

	const handleScroll = (e) => {
		window.onscroll = function(){
			var st = window.pageYOffset
			setScrollPos(st)
		}
	}

	const textArray = props.content[5].content.split(' ')
	textArray.shift()
	const text = textArray.join(' ')

	return (
		<div className='page-content page-default'>
			<Banner content={props.content} scroll={scrollPos} />
			<QuoteBar content={props.content} />
			<div className='page-container'>
				<Content content={props.content} header={props.content[0].content} />
				<Sidebar content={props.content} text={text} url={props.url} scroll={scrollPos} />
			</div>
		</div>
	)
}

export default () => (
	<Layout>
		<Page />
	</Layout>
)