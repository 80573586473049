import React from 'react'
import Layout from './_Components/Layout-Quote'

const Quote = ({ content }) => {

	return (
		<div className='page-quote-container'>
			<h1><span>Request a Quote</span> from Fleetwood Insurance</h1>
			<p className='pqc-sub-header'>Select one of the insurance types below to begin your free quote.</p>
			<div className='user-text' dangerouslySetInnerHTML={{ __html: content[0].content }} />
			<div className='link-grid'>
	    	<a className='lg' href='/quote/agriculture/null/null'>
	    		<i className="fal fa-farm" />
	    		<span>Agriculture</span>
	    	</a>
	    	<a className='lg' href='/quote/auto/null/null'>
	    		<i className="fal fa-car" />
	    		<span>Auto</span>
	    	</a>
	    	<a className='lg' href='/quote/business/null/null'>
	    		<i className="fal fa-building" />
	    		<span>Business</span>
	    	</a>
	    	<a className='lg' href='/quote/flood/null/null'>
	    		<i className="fal fa-house-flood" />
	    		<span>Flood</span>
	    	</a>
	    	<a className='lg' href='/quote/health/null/null'>
	    		<i className="fal fa-user-md" />
	    		<span>Health</span>
	    	</a>
	    	<a className='lg' href='/quote/high%20net%20worth/null/null'>
	    		<i className="fal fa-dollar-sign" />
	    		<span>High Net Worth</span>
	    	</a>
	    	<a className='lg' href='/quote/homeowners/null/null'>
	    		<i className="fal fa-home-lg" />
	    		<span>Homeowners</span>
	    	</a>
	    	<a className='lg' href='http://dockmastersinsurance.com/' target='_blank' rel='noopener noreferrer'>
	    		<i className="fal fa-anchor" />
	    		<span>Marine</span>
	    	</a>
	    	<a className='lg' href='/quote/personal/null/null'>
	    		<i className="fal fa-user-friends" />
	    		<span>Personal</span>
	    	</a>
	    	<a className='lg' href='/quote/renters/null/null'>
	    		<i className="fal fa-hotel" />
	    		<span>Renters</span>
	    	</a>
	    	<a className='lg' href='/quote/rv/null/null'>
	    		<i className="fal fa-rv fa-flip-horizontal" />
	    		<span>RV</span>
	    	</a>
	    	<a className='lg' href='/quote/trucking/null/null'>
	    		<i className="fal fa-truck-moving fa-flip-horizontal" />
	    		<span>Trucking</span>
	    	</a>
	    </div>
		</div>
	)
}

export default () => (
	<Layout>
		<Quote />
	</Layout>
)