import React from 'react';
import Link from './HomeSectionLink'
import { API_IMAGE_PATH } from './_Config'

export default ({ content, scroll }) => (
  <div className='home-ib'>
    <div className='home-ib-container'>
      <div className='hibc-image' style={{backgroundImage: 'url(' + API_IMAGE_PATH + content[3].content + ')'}}>
        <div className='hibc-block hibc-block-right' style={{marginLeft: scroll / 15 + 'px'}}>
          <h3>Insurance for Business</h3>
          <p>{content[4].content}</p>
          <a href='/business'>Business Insurance
            <i className='fas fa-long-arrow-alt-right'></i>
          </a>
        </div>
      </div>
      <div className='hibc-blocks'>
        <Link url='/business' text='Commercial Property' icon='fas fa-store' />
        <Link url='/business' text='Workers Compensation' icon='fas fa-users' />
        <Link url='/business' text='General Liability' icon='fas fa-gavel' />
        <Link url='/business' text='Cyber' icon='fas fa-user-lock' />
        <Link url='/business' text='Commercial Auto' icon='fas fa-truck-moving' />
        <Link url='/business' text='All Products' icon='fas fa-long-arrow-alt-right' />
      </div>
    </div>
  </div>
)