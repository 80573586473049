import React from 'react'
import PropTypes from 'prop-types'
import Layout from './_Components/Layout-Static'
import Banner from './_Components/PageBanner'
import GoogleMap from './_Components/Google-Map'

const Locations = React.memo(({ content }) => {

	const addressArray = content[5].content.split('*!*')
	addressArray.forEach((addr, i, a) => {
		const rows = addr.split('&')
		a[i] = rows
	})

	return (
		<div className='page-content page-default'>
			<Banner content={content} />
			<div className='location-content-container'>
				<div className='locations-content'>
			    <p className='pm-breadcrumbs' dangerouslySetInnerHTML={{ __html: content[1].content }} />
			    <h2 className='pd-title'>{content[0].content}</h2>
					<div className='addresses'>
						{addressArray.map((addr, i) => {
							return <div className='addr' key={i}>
								{addr.map((a, j) => {
									return <p key={j}>{a}</p>
								})}
								</div>
						})}
					</div>
				</div>
				<GoogleMap />
			</div>
		</div>
	)
})

Locations.propTypes = {
	content: PropTypes.array
}

export default () => (
	<Layout>
		<Locations />
	</Layout>
)