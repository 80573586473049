import React from 'react'

function Page() {
	return (
		<div className='page-content'>
			<h1>404 page</h1>
		</div>
	)
}

export default () => (
	<Page />
)