import React from 'react';

export default () => (
  <div className='ft-content'>
    <div className='ftc-top'>
      <a href='/quote'>Get a Quote
        <i className='fas fa-long-arrow-alt-right' />
      </a>
      <a href='/support/payments-and-claims'>Report a Claim
        <i className='fas fa-long-arrow-alt-right' />
      </a>
      <a href='/support'>Customer Support
        <i className='fas fa-long-arrow-alt-right' />
      </a>
    </div>
    <div className='ftc-main'>
      <a href='/personal'>Personal Insurance</a>
      <a href='/business'>Business Insurance</a>
      <a href='/marine'>Marine Insurance</a>
      <a href='/agriculture'>Agriculture Insurance</a>
      <a href='/about'>About Us</a>
      <a href='/about/staff'>Agents</a>
      <a href='/about/locations'>Locations</a>
    </div>
  </div>
)