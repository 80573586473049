import React, { useState, useEffect } from 'react'; //eslint-disable-line
import FooterNav from '../_Components/FooterNav'
import FooterCopyright from '../_Components/FooterCopyright'
import { API_IMAGE_PATH } from '../_Components/_Config'
import { getCookie, setCookie } from '../_Components/_Utils/Cookies'

export default () => {

	const closeCovid = () => {
		document.querySelector('.covid').style.display = 'none'
		setCookie('covid', true, 7)
	}

	return (
		<footer>
			{/*!getCookie('covid') &&
				<div className='covid'>
					<div className='container'>
						<p className='close-covid' onClick={closeCovid}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"/></svg></p>
						<h6>Fleetwood Insurance Group is closed to the public.</h6>
						<p>In order to protect the safety of our customers and staff, Fleetwood Insurance Group and BGV Insurance will be closed to the public as of 3/17/2020.</p>
						<p>We are closely monitoring the quickly-evolving COVID-19 situation and feel that it is the best interest of the health of our customers and staff to close to the public for now. We will continue to serve our clients through incredible customer service and support, but from behind closed doors. Fleetwood Insurance Group is available by phone at 410-778-2260 and BGV Insurance is available at 410-822-2400.</p>
					</div>
				</div>
			*/}
			<div className='footer-container'>
				<div className='footer-top'>
					<div className='ft-images'>
						<img src={API_IMAGE_PATH + 'fleetwood-icon.png'} alt='Fleetwood Icon' />
						<img src={API_IMAGE_PATH + 'bgv-icon.png'} alt='BGV Icon' />
						<img src={API_IMAGE_PATH + 'dockmasters-icon.png'} alt='Dockmasters Icon' />
					</div>
					<FooterNav />
				</div>
				<FooterCopyright />
			</div>
		</footer>
	)
}