import React from 'react'
import PropTypes from 'prop-types'
import Layout from './_Components/Layout-Static'
import Banner from './_Components/PageBanner'
import { API_IMAGE_PATH } from './_Components/_Config'

const Staff = React.memo(({ content }) => {
	return (
		<div className='page-content page-default'>
			<Banner content={content} />
			<div className='page-default-container'>
				<p className='pm-breadcrumbs' dangerouslySetInnerHTML={{ __html: content[1].content }} />
				<div className='staff'>
					{content[4].content.map((img, i) => {
						return <div className='agent' key={i}>
							<img src={ API_IMAGE_PATH + img } alt={img} />
							{content[5].content[i].map((detail, j) => {
								if (j === 2) {
									if (detail !== '#') return <a href={'mailto:' + detail} key={j}>{detail}</a>
									else return null
								}
								else if (j === 3) return <a href={'tel:' + (detail.replace(/p - /gm, "")).replace(/\./gm, "")} key={j}>{detail}</a>
								else return <p key={j}>{detail}</p>
							})}
						</div>
					})}
				</div>
		  </div>
		</div>
	)
})

Staff.propTypes = {
	content: PropTypes.array
}

export default () => (
	<Layout>
		<Staff />
	</Layout>
)