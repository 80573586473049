import React from 'react';

export default (props) => (
  <div className='hacb'>
    <div className='hacb-icon'>
      <i className={props.icon}></i>
    </div>
    <div className='hacb-content'>
      <h4>{props.header}</h4>
      <p>{props.text}</p>
    </div>
  </div>
)