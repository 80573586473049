import PolicyDetails from './Policy-Details.json'
import Additional from './Support-Additional.json'
import ChangeofAddress from './Change-of-Address.json'

import Vehicle from './Auto-Vehicle.json'
import Driver from './Auto-Driver.json'
import Coverage from './Auto-Coverage.json'

import AutoIDCards from './Auto-ID-Cards.json'

import CertificateRecipient from './Certificate-Recipient.json'
import CertificateDetails from './Certificate-Details.json'

import PolicyChanges from './Policy-Changes.json'

//================
//= Util for name formatting
//================

function nameToInputName(name) {
	const clean = name.replace(/[^a-zA-Z0-9 ]/g, '')
	var inputName = clean.replace(/ /gm, "_").toLowerCase()
	if (inputName.charAt(inputName.length) === '_') {
		inputName = inputName.slice(0, -1)
	}
	return inputName
}

//================
// Setting initial variables
//================

const createObject = data => {
	var id = 0
	var view = []
	var state = []

	const setObject = (node, id) => {
		var temp = {
			type: node.type,
			name: node.name,
			required: node.required ? node.required : false,
			inputName: id + '_' + nameToInputName(node.name)
		}
		if (node.options) temp.options = node.options
		if (node.description) temp.description = node.description
		if (node.display) temp.display = node.display
		if (node.width) temp.width = node.width
		if (node.margin) temp.margin = node.margin
		if (node.text) temp.text = node.text
		if (node.checked !== undefined) temp.checked = node.checked
		return temp
	}

	data.forEach((section, i) => {
		let temp = []
		section.forEach((group, j) => {
			temp.push(setObject(data[i][j], id))
			state[id + '_' + nameToInputName(data[i][j].name)] = ''
			id++
		})
		view.push(temp)
	})
	return {view, state}
}

//================
// Setting returned object
//================

export default function(type) {
	if (type === 'Change of Address') {
		const data = [PolicyDetails, ChangeofAddress, Additional]
		const res = createObject(data)
		return {
			FormState: res.state, Inputs: res.view[0], Inputs1: res.view[1], Inputs2: res.view[2]
		}
	} else if (type === 'Update Vehicles/Drivers') {
		const data = [PolicyDetails, Vehicle, Vehicle, Driver, Driver, Coverage, Additional]
		const res = createObject(data)
		return {
			FormState: res.state, Inputs: res.view[0], Inputs1: res.view[1], Inputs2: res.view[2], Inputs3: res.view[3], Inputs4: res.view[4], Inputs5: res.view[5], Inputs6: res.view[6]
		}
	} else if (type === 'Auto ID Cards') {
		const data = [PolicyDetails, AutoIDCards, Additional]
		const res = createObject(data)
		return {
			FormState: res.state, Inputs: res.view[0], Inputs1: res.view[1], Inputs2: res.view[2]
		}
	} else if (type === 'Certificate of Insurance') {
		const data = [PolicyDetails, CertificateRecipient, CertificateDetails, Additional]
		const res = createObject(data)
		return {
			FormState: res.state, Inputs: res.view[0], Inputs1: res.view[1], Inputs2: res.view[2], Inputs3: res.view[3]
		}
	} else if (type === 'Policy Change') {
		const data = [PolicyDetails, PolicyChanges, Additional]
		const res = createObject(data)
		return {
			FormState: res.state, Inputs: res.view[0], Inputs1: res.view[1], Inputs2: res.view[2]
		}
	}
}