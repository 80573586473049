import React from 'react'
import PropTypes from 'prop-types'
import Layout from './_Components/Layout-Static'

const Legal = React.memo(({ content }) => {

	return (
		<div className='page-content page-legal-content'>
			<div className='user-text' dangerouslySetInnerHTML={{ __html: content[0].content }} />
		</div>
	)
})

Legal.propTypes = {
	content: PropTypes.array
}

export default () => (
	<Layout>
		<Legal />
	</Layout>
)