import React from 'react'
import PropTypes from 'prop-types'
import Layout from './_Components/Layout-Static'
import Banner from './_Components/PageBanner'

const Support = React.memo(({ content }) => {
	return (
		<div className='page-content page-default'>
			<Banner content={content} />
			<div className='page-default-container'>
				<p className='pm-breadcrumbs' dangerouslySetInnerHTML={{ __html: content[1].content }} />
		    <h2 className='pd-title'>{content[0].content}</h2>
		    <div className='user-text' dangerouslySetInnerHTML={{ __html: content[4].content}} />
		    <div className='link-grid'>
		    	<a className='lg' href='/support/payments-and-claims'>
		    		<i className="fal fa-credit-card" />
		    		<span>Make a Payment or Claim</span>
		    	</a>
		    	<a className='lg' href='/support/r/change-of-address'>
		    		<i className="fal fa-house" />
		    		<span>Change of Address</span>
		    	</a>
		    	<a className='lg' href='/support/r/update-vehicles-drivers'>
		    		<i className="fal fa-car" />
		    		<span>Update Vehicles/Drivers</span>
		    	</a>
		    	<a className='lg' href='/support/r/auto-id-cards'>
		    		<i className="fal fa-id-card" />
		    		<span>Auto ID Cards</span>
		    	</a>
		    	<a className='lg' href='/support/r/certificate-of-insurance'>
		    		<i className="fal fa-file-certificate" />
		    		<span>Certificate of Insurance</span>
		    	</a>
		    	<a className='lg' href='/support/r/policy-change'>
		    		<i className="fal fa-file-alt" />
		    		<span>Change Your Policy</span>
		    	</a>
		    </div>
		  </div>
		</div>
	)
})

Support.propTypes = {
	content: PropTypes.array
}

export default () => (
	<Layout>
		<Support />
	</Layout>
)