import React from 'react'
import PropTypes from 'prop-types'
import Layout from './_Components/Layout-Static'
import Banner from './_Components/PageBanner'
import { API_IMAGE_PATH } from './_Components/_Config'

const About = React.memo(({ content }) => {
	return (
		<div className='page-content page-default'>
			<Banner content={content} />
			<div className='page-default-container'>
				<p className='pm-breadcrumbs' dangerouslySetInnerHTML={{ __html: content[1].content }} />
		  	<h2 className='pd-title'>{content[0].content}</h2>
		  	<div className='user-text' dangerouslySetInnerHTML={{ __html: content[4].content}} />
		  	<div className='com-images'>
		  		{content[5].content.map((img, i) => {
		  			return <div className='ci-img' key={i}>
		  				<img src={API_IMAGE_PATH + img} alt={img} />
		  				<hr />
		  				<div className='text'>
		  					<p>{content[6].content[i]}</p>
		  				</div>
		  			</div>
		  		})}
		  	</div>
		  </div>
		</div>
	)
})

About.propTypes = {
	content: PropTypes.array
}

export default () => (
	<Layout>
		<About />
	</Layout>
)