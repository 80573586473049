import React from 'react';
import { Helmet } from "react-helmet";

export default () => {
  return (
    <Helmet>
      <link href='https://fonts.googleapis.com/css?family=Work+Sans:400,500,700' rel='stylesheet' />
      <link rel="icon" href="/media/favicon.ico" type="image/x-icon" />
      <link rel="shortcut icon" href="/static/media/favicon.ico" type="image/x-icon" />
    </Helmet>
  )
}