import React from 'react';

export default () => {
  const year = new Date().getFullYear()
  return (
    <div className='footer-bottom'>
      <p>&copy;{year} Fleetwood Insurance Group</p>
      <a href='/privacy-policy'>Privacy Policy</a>
      <a href='/site-map'>Site Map</a>
    </div>
  )
}