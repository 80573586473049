import React, { useState, useEffect } from 'react'
import Submit from './_Components/_API/Submit'
import Layout from './_Components/Layout-Customer-Support-Request'
import FormSection from './_Components/FormSection'
import Render from './_Components/_FormData/Render-Customer-Support'

const CustomerSupport = ({ type, firstN, lastN }) => {

	const Data = Render(type)

	const [ submitted, setSubmitted ] = useState(false)
	const [ scrollPos, setScrollPos ] = useState(0)
	const [ first, setFirst ] = useState(firstN)
	const [ last, setLast ] = useState(lastN) // eslint-disable-line
	const [ data, setData ] = useState({...Data.FormState})

	useEffect(() => {
		window.addEventListener('scroll', handleScroll)
		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	})

	const handleScroll = (e) => {
		window.onscroll = function(){
			var st = window.pageYOffset
			setScrollPos(st)
		}
	}

	const handleSubmit = (e) => {
		Submit(window.location.pathname, data)
		setFirst(data['2_first_name'])
		setSubmitted(true)
		e.preventDefault()
	}

	const updateValue = (e) => {
		const name = e.target.name
		const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
		const values = data
		setData(prev => {
			values[name] = value
			return { ...prev }
		})
	}

	const quoteSections = () => {
		if (type === 'Change of Address') {
			return (
				<React.Fragment>
					<FormSection name='1.) Current Policy Information' inputs={Data.Inputs} updateValue={updateValue} />
					<FormSection name='2.) Updated Personal Information' inputs={Data.Inputs1} updateValue={updateValue} scroll={scrollPos} />
					<FormSection name='3.) Additional Information' inputs={Data.Inputs2} updateValue={updateValue} scroll={scrollPos} />
				</React.Fragment>
			)
		} else if (type === 'Update Vehicles/Drivers') {
			return (
				<React.Fragment>
					<FormSection name='1.) Current Policy Information' inputs={Data.Inputs} updateValue={updateValue} />
					<FormSection name='2.) Vehicle(s) To Add' inputs={Data.Inputs1} multiple={[Data.Inputs2]} multipleLabel='Add another vehicle' updateValue={updateValue} scroll={scrollPos} />
					<FormSection name='3.) Driver(s) To Add' inputs={Data.Inputs3} multiple={[Data.Inputs4]} multipleLabel='Add another driver' updateValue={updateValue} scroll={scrollPos} />
					<FormSection name='4.) Coverage Information' inputs={Data.Inputs5} updateValue={updateValue} scroll={scrollPos} />
					<FormSection name='5.) Additional Information (put removals in comments)' inputs={Data.Inputs6} updateValue={updateValue} scroll={scrollPos} />
				</React.Fragment>
			)
		} else if (type === 'Auto ID Cards') {
			return (
				<React.Fragment>
					<FormSection name='1.) Personal/Business Information' inputs={Data.Inputs} updateValue={updateValue} />
					<FormSection name='2.) Auto ID Cards' inputs={Data.Inputs1} updateValue={updateValue} scroll={scrollPos} />
					<FormSection name='3.) Additional Information' inputs={Data.Inputs2} updateValue={updateValue} scroll={scrollPos} />
				</React.Fragment>
			)
		} else if (type === 'Certificate of Insurance') {
			return (
				<React.Fragment>
					<FormSection name='1.) Current Policy Information' inputs={Data.Inputs} updateValue={updateValue} />
					<FormSection name='2.) Certificate Recipient' inputs={Data.Inputs1} updateValue={updateValue} scroll={scrollPos} />
					<FormSection name='3.) Certificate Details' inputs={Data.Inputs2} updateValue={updateValue} scroll={scrollPos} />
					<FormSection name='5.) Additional Information' inputs={Data.Inputs3} updateValue={updateValue} scroll={scrollPos} />
				</React.Fragment>
			)
		} else { // Policy change
			return (
				<React.Fragment>
					<FormSection name='1.) Current Policy Information' inputs={Data.Inputs} updateValue={updateValue} />
					<FormSection name='2.) Policy Changes' inputs={Data.Inputs1} updateValue={updateValue} scroll={scrollPos} />
					<FormSection name='3.) Additional Information' inputs={Data.Inputs2} updateValue={updateValue} scroll={scrollPos} />
				</React.Fragment>
			)
		} 
	}

	if (submitted) {
		return (
			<div className='page-quote-container'>
				<p>Thanks, {first}. We've received your {type} request. We'll review the information provided and get in touch with you shortly!</p>
			</div>
		)
	} else {
		return (
			<div className='page-quote-container'>
				<h1><span>{type}</span> Request</h1>
				<div className='user-text'>
					<p>Please fill out the complete form below and submit your request to us.</p>
				</div>
				<form method='post' action='' onSubmit={handleSubmit}>
					<input type='hidden' name='request_type' value={type} />
					{quoteSections()}
					<input type='submit' value='Submit Request' />
				</form>
				<div className='cs-form-disclosure'>
					<p>Note: By submitting this form you understand that no coverage is bound until you receive written notice. You also agree to release us from any liability if this information is accidentally viewed by unauthorized others. We will only use this information for insurance quoting purposes and not distribute to other parties.</p>
				</div>
			</div>
		)
	}
}

export default () => (
	<Layout>
		<CustomerSupport />
	</Layout>
)