import React from 'react'
import PropTypes from 'prop-types'

const Select = React.memo((props) => {

	return (
		<label htmlFor={props.inputName} style={{display: (props.display) || 'inline-block', width: (props.width) || '100%'}}>{props.name}
			{props.required &&
				<span className='required'>required</span>
			}
			<select
				name={props.inputName}
				required={props.required ? props.required : false}
				onChange={e => props.updateValue(e)}
				defaultValue={props.value ? props.value : ''}>
				{props.options.map((option, index) =>
					<option
						key={index}
						value={option}>{option}</option>
				)}
			</select>
		</label>
	)
})

Select.propTypes = {
	name: PropTypes.string.isRequired,
	inputName: PropTypes.string.isRequired,
	updateValue: PropTypes.func,
	type: PropTypes.string,
	display: PropTypes.string,
	margin: PropTypes.string,
	required: PropTypes.bool,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	options: PropTypes.array
}

export default Select