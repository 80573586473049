import React from 'react';

export default ({ content, header }) => {
  return (
    <div className='page-main'>
      <p className='pm-breadcrumbs' dangerouslySetInnerHTML={{ __html: content[1].content }} />
      {header !== 'Hunting Insurance' && <h2>{header}</h2>}
      <div className='user-text' dangerouslySetInnerHTML={{__html: content[4].content}} />
    </div>
  )
}