import React from 'react'
import PropTypes from 'prop-types'
import StaticLayout from './_Components/Layout-Static'

const Events = React.memo(({ events }) => {

	return (
		<div className='page-content'>
			<div className='posts'>
				<h1>Events</h1>
				{events.map((e,i) => {
					return <div className='event' key={i}>
						<p>{e.dateShort}</p>
					</div>
				})}
			</div>
		</div>
	)
})

Events.propTypes = {
	events: PropTypes.array
}

export default () => (
	<StaticLayout>
		<Events />
	</StaticLayout>
)