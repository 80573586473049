import React, { useState } from 'react'
import PropTypes from 'prop-types'

const Checkbox = React.memo((props) => {

	const [ active, setActive ] = useState(false)

	const toggle = (e) => {
		props.updateValue(e)
		setActive(!active)
	}

	return (
		<div className={(active) ? 'checkbox checkbox-active' : 'checkbox'}>
			<label htmlFor={props.inputName} style={{display: (props.display) || 'inline-block', width: (props.width) || '100%'}}>{props.name}
				{props.required &&
					<span className='required'>required</span>
				}
				<input
					type='checkbox'
					name={props.inputName}
					id={props.inputName}
					required={props.required ? props.required : false}
					onChange={toggle}
					checked={props.value}
				/>
				<span className='icon'></span>
				<p>{props.text}</p>
			</label>
		</div>
	)
})

Checkbox.propTypes = {
	name: PropTypes.string.isRequired,
	inputName: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	updateValue: PropTypes.func,
	display: PropTypes.string,
	margin: PropTypes.string,
	required: PropTypes.bool,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
}

export default Checkbox