import React, { useState, useEffect } from 'react'
import Submit from './_Components/_API/Submit'
import Layout from './_Components/Layout-Quote'
import FormSection from './_Components/FormSection'
import Render from './_Components/_FormData/Render-Quote'

const Quote = ({ type, firstN, lastN }) => {

	const Data = Render(type)

	const [ submitted, setSubmitted ] = useState(false)
	const [ scrollPos, setScrollPos ] = useState(0)
	const [ first, setFirst ] = useState(firstN)
	const [ last, setLast ] = useState(lastN) // eslint-disable-line
	const [ data, setData ] = useState({...Data.FormState})

	useEffect(() => {
		window.addEventListener('scroll', handleScroll)
		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	})

	const handleScroll = (e) => {
		window.onscroll = function(){
			var st = window.pageYOffset
			setScrollPos(st)
		}
	}

	const handleSubmit = (e) => {
		Submit(window.location.pathname, data)
		setFirst(data['2_first_name'])
		setSubmitted(true)
		e.preventDefault()
	}

	const updateValue = (e) => {
		const name = e.target.name
		const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
		const values = data
		setData(prev => {
			values[name] = value
			return { ...prev }
		})
	}

	//----------------
	//- If first/last name initially set
	//----------------

	useEffect(() => {
		const values = data
		setData(prev => {
			values['2_first_name'] = firstN
			values['3_last_name'] = lastN
			return { ...prev }
		})
	},[]) //eslint-disable-line

	const quoteSections = () => {
		if (type === 'Agriculture') {
			return (
				<React.Fragment>
					<FormSection name='1.) Personal/Farm Information' inputs={Data.AgInputs} first={first} last={last} updateValue={updateValue} />
					<FormSection name='2.) Values' inputs={Data.AgValues} updateValue={updateValue} scroll={scrollPos} />
					<FormSection name='3.) Coverage Information' inputs={Data.AgCoverage} updateValue={updateValue} scroll={scrollPos} />
					<FormSection name='4.) Losses' inputs={Data.AgLosses} updateValue={updateValue} scroll={scrollPos} />
					<FormSection name='5.) Additional Information' inputs={Data.AdditionalInputs} updateValue={updateValue} scroll={scrollPos} />
				</React.Fragment>
			)
		} else if (type === 'Auto') {
			return (
				<React.Fragment>
					<FormSection name='1.) Personal Information' inputs={Data.PersonalInputs} first={first} last={last} updateValue={updateValue} />
					<FormSection name='2.) Vehicle Information' inputs={Data.VehicleInputs} multiple={[Data.VehicleInputs2]} multipleLabel='Add another vehicle' updateValue={updateValue} scroll={scrollPos} />
					<FormSection name='3.) Driver Information' inputs={Data.DriverInputs} multiple={[Data.DriverInputs2]} multipleLabel='Add another driver' updateValue={updateValue} scroll={scrollPos} />
					<FormSection name='4.) Coverage Information' inputs={Data.CoverageInputs} updateValue={updateValue} scroll={scrollPos} />
					<FormSection name='5.) Prior Policy Information' inputs={Data.PriorPolicyInputs} updateValue={updateValue} scroll={scrollPos} />
					<FormSection name='6.) Additional Information' inputs={Data.AdditionalInputs} updateValue={updateValue} scroll={scrollPos} />
				</React.Fragment>
			)
		} else if (type === 'Business') {
			return (
				<React.Fragment>
					<FormSection name='1.) Personal/Business Information' inputs={Data.BusinessInputs} first={first} last={last} updateValue={updateValue} />
					<FormSection name='2.) Additional Information' inputs={Data.AdditionalInputs} updateValue={updateValue} scroll={scrollPos} />
				</React.Fragment>
			)
		} else if (type === 'Flood') {
			return (
				<React.Fragment>
					<FormSection name='1.) Personal/Property Information' inputs={Data.FloodInputs} first={first} last={last} updateValue={updateValue} />
					<FormSection name='2.) Coverage Information' inputs={Data.FloodCoverage} first={first} last={last} updateValue={updateValue} />
					<FormSection name='3.) Losses/Claims' inputs={Data.FloodLosses} first={first} last={last} updateValue={updateValue} />
					<FormSection name='4.) Additional Information' inputs={Data.AdditionalInputs} updateValue={updateValue} scroll={scrollPos} />
				</React.Fragment>
			)
		} else if (type === 'Health') {
			return (
				<React.Fragment>
					<FormSection name='1.) Personal Information' inputs={Data.PersonalInputs} first={first} last={last} updateValue={updateValue} />
					<FormSection name="2.) Personal Information, con't" inputs={Data.HealthInputs} updateValue={updateValue} scroll={scrollPos} />
					<FormSection name='3.) Additional Information' inputs={Data.AdditionalInputs} updateValue={updateValue} scroll={scrollPos} />
				</React.Fragment>
			)
		} else if (type === 'Homeowners') {
			return (
				<React.Fragment>
					<FormSection name='1.) Personal/Property Information' inputs={Data.HomeInputs} first={first} last={last} updateValue={updateValue} />
					<FormSection name="2.) Coverage Information" inputs={Data.HomeCov} updateValue={updateValue} scroll={scrollPos} />
					<FormSection name="3.) Property Losses" inputs={Data.HomeLosses} updateValue={updateValue} scroll={scrollPos} />
					<FormSection name='4.) Additional Information' inputs={Data.AdditionalInputs} updateValue={updateValue} scroll={scrollPos} />
				</React.Fragment>
			)
		} else if (type === 'Renters') {
			return (
				<React.Fragment>
					<FormSection name='1.) Personal/Property Information' inputs={Data.HomeInputs} first={first} last={last} updateValue={updateValue} />
					<FormSection name="2.) Coverage Information" inputs={Data.RentersCov} updateValue={updateValue} scroll={scrollPos} />
					<FormSection name="3.) Property Losses" inputs={Data.HomeLosses} updateValue={updateValue} scroll={scrollPos} />
					<FormSection name='4.) Additional Information' inputs={Data.AdditionalInputs} updateValue={updateValue} scroll={scrollPos} />
				</React.Fragment>
			)
		} else if (type === 'Trucking') {
			return (
				<React.Fragment>
					<FormSection name='1.) Personal/Business Information' inputs={Data.Truck} first={first} last={last} updateValue={updateValue} />
					<FormSection name="2.) Operations" inputs={Data.TruckOps} updateValue={updateValue} scroll={scrollPos} />
					<FormSection name="3.) Operation Areas" inputs={Data.TruckAreas} updateValue={updateValue} scroll={scrollPos} />
					<FormSection name="4.) Commodities Hauled" inputs={Data.TruckComm} updateValue={updateValue} scroll={scrollPos} />
					<FormSection name="5.) Last Year Revenue/Mileage History" inputs={Data.TruckLY} updateValue={updateValue} scroll={scrollPos} />
					<FormSection name="6.) This Year Revenue/Mileage Estimates" inputs={Data.TruckTY} updateValue={updateValue} scroll={scrollPos} />
					<FormSection name="7.) Next Year Revenue/Mileage Estimates" inputs={Data.TruckNY} updateValue={updateValue} scroll={scrollPos} />
					<FormSection name="8.) Equipment Information" inputs={Data.TruckEquip} updateValue={updateValue} scroll={scrollPos} />
					<FormSection name="9.) Policy Information" inputs={Data.TruckPolicy} updateValue={updateValue} scroll={scrollPos} />
					<FormSection name='10.) Additional Information' inputs={Data.AdditionalInputs} updateValue={updateValue} scroll={scrollPos} />
				</React.Fragment>
			)
		} else if (type === 'Workers Compensation') {
			return (
				<React.Fragment>
					<FormSection name='1.) Personal/Business Information' inputs={Data.Business} first={first} last={last} updateValue={updateValue} />
					<FormSection name="2.) Business Details" inputs={Data.Details} updateValue={updateValue} scroll={scrollPos} />
					<FormSection name='3.) Owner Information' inputs={Data.Owner1} multiple={[Data.Owner2,Data.Owner3,Data.Owner4]} multipleLabel='Add another owner' updateValue={updateValue} scroll={scrollPos} />
					<FormSection name='4.) Employee Information' inputs={Data.Group1} multiple={[Data.Group2,Data.Group3,Data.Group4]} multipleLabel='Add another employee group' updateValue={updateValue} scroll={scrollPos} />
					<FormSection name="5.) Misc. Business Information" inputs={Data.Misc} updateValue={updateValue} scroll={scrollPos} />
					<FormSection name="6.) Coverage Information" inputs={Data.Coverage} updateValue={updateValue} scroll={scrollPos} />
					<FormSection name='7.) Additional Information' inputs={Data.AdditionalInputs} updateValue={updateValue} scroll={scrollPos} />
				</React.Fragment>
			)
		} else { // High Net Worth, RV
			return (
				<React.Fragment>
					<FormSection name='1.) Personal Information' inputs={Data.PersonalInputs} first={first} last={last} updateValue={updateValue} />
					<FormSection name='2.) Additional Information' inputs={Data.AdditionalInputs} updateValue={updateValue} scroll={scrollPos} />
				</React.Fragment>
			)
		}
	}

	if (submitted) {
		return (
			<div className='page-quote-container'>
				<p>Thanks, {first}. We've received your {type} insurance quote request. We'll review the information provided and get in touch with you shortly!</p>
			</div>
		)
	} else {
		return (
			<div className='page-quote-container'>
				<h1>Quote Request for <span>{type} Insurance</span></h1>
				<div className='user-text'>
					<p>Please fill out and submit the form below to receive a free insurance quote. Not all fields are required but help us to give you a more accurate quote.</p>
				</div>
				<form method='post' action='' onSubmit={handleSubmit}>
					<input type='hidden' name='quote_type' value={type + ' Insurance'} />
					{quoteSections()}
					<input type='submit' value='Submit for Quote' />
				</form>
			</div>
		)
	}
}

export default () => (
	<Layout>
		<Quote />
	</Layout>
)