import React from 'react'
import PropTypes from 'prop-types'
import Layout from './_Components/Layout-Static'
import Banner from './_Components/PageBanner'
import { API_IMAGE_PATH } from './_Components/_Config'

const About = React.memo(({ content }) => {
	return (
		<div className='page-content page-default'>
			<Banner content={content} />
			<div className='page-default-container'>
				<p className='pm-breadcrumbs' dangerouslySetInnerHTML={{ __html: content[1].content }} />
		    {/*************************/}
		    <div className='link-grid'>
		    	{/*<a className='lg' href='/about/companies'>
		    		<i className="fal fa-building" />
		    		<span>Companies We Offer</span>
		    	</a>*/}
		    	<a className='lg' href='/about/staff'>
		    		<i className="fal fa-users" />
		    		<span>Meet Our Team</span>
		    	</a>
		    	<a className='lg' href='/about/locations'>
		    		<i className="fal fa-search-location" />
		    		<span>Office Locations</span>
		    	</a>
		    	<a className='lg' href='/about/community'>
		    		<i className="fal fa-ribbon" />
		    		<span>Community Impact</span>
		    	</a>
		    </div>
		  	{/*************************/}
		    <h2 className='pd-title'>{content[0].content}</h2>
		    <div className='user-text' dangerouslySetInnerHTML={{ __html: content[4].content}} />
		    <div className='pdc-middle-images'>
		    	<div className='pdcmi-left'>
		    		<figure>
		    			<img src={API_IMAGE_PATH + content[5].content[0]} alt={content[5].content[0]} />
		    			<figcaption>{content[6].content[0]}</figcaption>
		    		</figure>
		    	</div>
		    	<div className='pdcmi-right'>
		    		<figure>
		    			<img src={API_IMAGE_PATH + content[5].content[1]} alt={content[5].content[1]} />
		    			<figcaption>{content[6].content[1]}</figcaption>
		    		</figure>
		    		<figure>
		    			<img src={API_IMAGE_PATH + content[5].content[2]} alt={content[5].content[2]} />
		    			<figcaption>{content[6].content[2]}</figcaption>
		    		</figure>
		    	</div>
		    </div>
		    <div className='user-text' dangerouslySetInnerHTML={{ __html: content[7].content}} />
		  </div>
		</div>
	)
})

About.propTypes = {
	content: PropTypes.array
}

export default () => (
	<Layout>
		<About />
	</Layout>
)