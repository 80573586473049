import React from 'react';

export default () => {
  return (
    <div className='mobile-nav-container'>
    	<div className='mnc-background'></div>
      <a href='/'>Home</a>
      <a href='/personal'>Personal Insurance</a>
      <a href='/personal/health'>Health Insurance</a>
      <a href='/personal/auto'>Auto Insurance</a>
      <a href='/personal/homeowners'>Homeowners Insurance</a>
      <a href='/personal/renters'>Renters Insurance</a>
      <a href='/personal/rv'>RV Insurance</a>
      <a href='/business'>Business Insurance</a>
      <a href='/marine'>Marine Insurance</a>
      <a href='/agriculture'>Agriculture Insurance</a>
      <a href='/high-net-worth'>High-net-worth Insurance</a>
      <a href='/support'>Customer Support</a>
      <a href='/support/payments-and-claims'>Payments/Claims</a>
      <a href='/about'>About Us</a>
      <a href='/contact'>Contact Us</a>
    </div>
  )
}