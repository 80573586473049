import React, { useState } from 'react'
import StaticLayout from './_Components/Layout-Static'
import Submit from './_Components/_API/Submit'
import ConfirmationMessage from './_Components/ConfirmationMessage'
import Render from './_Components/_FormData/Render-General'
import GoogleMap from './_Components/Google-Map'

import Input from './_Components/_FormElements/Input'
import Select from './_Components/_FormElements/Select'
import Checkbox from './_Components/_FormElements/Checkbox'
import Textarea from './_Components/_FormElements/Textarea'

const Page = React.memo(({ content }) => {

	const Data = Render()
	const [ submitted, setSubmitted ] = useState(false)
	const [ data, setData ] = useState({...Data.State})
	const [ first, setFirst ] = useState(null)

	const handleSubmit = (e) => {
		Submit(window.location.pathname, data)
		setFirst(data['2_first_name'])
		setSubmitted(true)
		e.preventDefault()
	}

	const updateValue = (e) => {
		const name = e.target.name
		const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
		const values = data
		setData(prev => {
			values[name] = value
			return { ...prev }
		})
	}
	
	const addressArray = content[5].content.split('*!*')
	addressArray.forEach((addr, i, a) => {
		const rows = addr.split('&')
		a[i] = rows
	})

	const generateFormComponent = (input, index = null) => {
		switch(input.type) {
			case 'select':
				return <Select key={index} {...input} value={Data.Inputs[input.inputName]} updateValue={updateValue} />
			case 'checkbox':
				return <Checkbox key={index} {...input} value={Data.Inputs[input.inputName]} updateValue={updateValue} />
			case 'textarea':
				return <Textarea key={index} {...input} value={Data.Inputs[input.inputName]} updateValue={updateValue} />
			default:
				return <Input key={index} {...input} value={Data.Inputs[input.inputName]} updateValue={updateValue} />
		}
	}
	
	const inputComponents = (inputs) => {
		return inputs.map((input, index) => {
			return generateFormComponent(input, index)
		})
	}

	return (
		<div className='contact-form-container'>
			<p className='pm-breadcrumbs' dangerouslySetInnerHTML={{ __html: content[1].content }} />
	    <h2 className='pd-title'>{content[0].content}</h2>
	    <div className='user-text' dangerouslySetInnerHTML={{ __html: content[4].content }} />
			{submitted
			? <ConfirmationMessage first={first} />
			: <React.Fragment>
					<form method='post' action='' onSubmit={handleSubmit}>
						{inputComponents(Data.Inputs)}
						<input type='submit' value='Send Message' />
					</form>
				</React.Fragment>
			}
			<div className='location-content-container'>
				<div className='locations-content'>
					<div className='addresses'>
						{addressArray.map((addr, i) => {
							return <div className='addr' key={i}>
								{addr.map((a, j) => {
									return <p key={j}>{a}</p>
								})}
								</div>
						})}
					</div>
				</div>
				<GoogleMap />
			</div>
		</div>
	)
})

export default () => (
	<StaticLayout>
		<Page />
	</StaticLayout>
)