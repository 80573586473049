import React, { useState, useEffect } from 'react';

const Quote = React.memo((props) => {

	const [ first, setFirst ] = useState('null')
	const [ last, setLast ] = useState('null') // eslint-disable-line
	const [ selectDisplay, setDisplay ] = useState('none')
	const [ selectedOption, setOption ] = useState('Auto')
	const [ url, setUrl ] = useState('/quote/auto/null/null')

	const handleChange = (e) => {
		const target = e.target
		const value = target.value !== '' ? target.value : null
		const name = target.name
		if (name === 'first') {
			setFirst(value)
			setUrl('/quote/' + selectedOption + '/' + value + '/' + last)
		} else {
			setLast(value)
			setUrl('/quote/' + selectedOption + '/' + first + '/' + value)
		}
	}

	const clickSelect = () => {
		const display = selectDisplay
		setDisplay((display === 'none') ? 'block' : 'none')
	}

	const clickOption = (e) => {
		const option = e.target
		const text = option.textContent
		setDisplay('none')
		setOption(text)
		setUrl('/quote/' + text.toLowerCase().replace(/ /g, '-') + '/' + first + '/' + last)
	}

	useEffect(() => {
		const pathArray = window.location.pathname.split('/')
		if (pathArray[1] === 'personal' || pathArray[1] === 'business' || pathArray[1] === 'marine' || pathArray[1] === 'agriculture' || pathArray[1] === 'high-net-worth') {
			if (pathArray.length > 2) {
				if (pathArray[2] === 'rv') {
					setOption('RV')
					setUrl('/quote/rv/' + first + '/' + last)
				} else {
					setOption(pathArray[2].charAt(0).toUpperCase() + pathArray[2].substr(1))
					setUrl('/quote/' + pathArray[2] + '/' + first + '/' + last)
				}
			} else {
				setOption(pathArray[1].charAt(0).toUpperCase() + pathArray[1].substr(1))
				setUrl('/quote/' + pathArray[1] + '/' + first + '/' + last)
			}
		}
	},[]) // eslint-disable-line

	return (
		<div className='quote-bar'>
			<div className='qb-container'>
				<p>Get a quote now or call 410-778-2260 (Chestertown) or 410-822-2400 (Easton)</p>
				<div className='hqc-form'>
					<div className='hqc-type'>
						<p className='hqct-active' onClick={clickSelect}>
							<span>{selectedOption}</span>
							<i className='fas fa-chevron-down'></i>
						</p>
						<div className='hqct-container' style={{display: selectDisplay}}>
							<p onClick={clickOption}>Agriculture</p>
							<p onClick={clickOption}>Auto</p>
							<p onClick={clickOption}>Business</p>
							<p onClick={clickOption}>Flood</p>
							<p onClick={clickOption}>Health</p>
							<p onClick={clickOption}>High Net Worth</p>
							<p onClick={clickOption}>Homeowners</p>
							<p onClick={clickOption}>Marine</p>
							<p onClick={clickOption}>Renters</p>
							<p onClick={clickOption}>RV</p>
							<p onClick={clickOption}>Trucking</p>
							<p onClick={clickOption}>Workers Compensation</p>
						</div>
					</div>
					<div className='hqc-first'>
						<input type='text' name='first' className='hqcf-active' placeholder='First Name' value={first === 'null' ? '' : first} onChange={handleChange}/>
					</div>
					<div className='hqc-last'>
						<input type='text' name='last' className='hqcl-active' placeholder='Last Name' value={last === 'null' ? '' : last} onChange={handleChange}/>
					</div>
				</div>
				<a href={(selectedOption === 'Marine') ? 'http://dockmastersinsurance.com/' : url} className='start-quote'>Start Quote
					<i className='fas fa-long-arrow-alt-right'></i>
				</a>
			</div>
		</div>
	)
})

export default Quote