import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Layout from './_Components/Layout-Admin'
import { API_PATH } from './_Components/_Config'
import Call from './_Components/_API/Call'

const Quotes = ({ types, queries }) => {

	const [ active, setActive ] = useState(types[0])

	const toggle = (tab) => {
		setActive(tab)
	}

	//const exportAllQuotes = () => window.location.replace(API_PATH + '/admin/queries/export-all-quotes')
	const exportAllType = () => window.location.replace(API_PATH + '/admin/queries/export-type/quote-' + active)
	const exportAsCsv = (id) => window.location.replace(API_PATH + '/admin/queries/export-id/' + id)

	const remove = (id) => {
		if (window.confirm('Are you sure that you want to permanently delete this quote request?')) {
			Call('/admin/queries/remove-id/' + id)
			window.location.replace('/admin/quotes')
		}
	}

	return (
		<div className='admin-vert-list'>
			<div className='avl-tabs'>
				{types.map((t,i) => {
					return <p key={i} className={(active === t) ? 'avct-active' : ''} onClick={() => toggle(t)}>{t.charAt(0).toUpperCase() + t.substr(1)}</p>
				})}
				{/*<p onClick={exportAllQuotes}>Export All</p>*/}
			</div>
			<div className='avl-tab-button'>
				<p className='export-quote-type' onClick={exportAllType}>Export All {active.charAt(0).toUpperCase() + active.substr(1)} Quotes</p>
			</div>
			{queries.map((q,i) => {
				return <div className={(active === q.type) ? 'avl-post avlp-active' : 'avl-post'} key={i} id={i} >
					<div className='quote'>
						<div className='quote-fields-header'>
							<p>{q.data[2]} {q.data[3]} on {q.contact_date}</p>
							<p className='qfh-actions'>
								<span onClick={() => exportAsCsv(q.ID)}>Export</span>
								<span onClick={() => remove(q.ID)}>Remove</span>
							</p>
						</div>
						<div className='quote-fields'>
							{q.data.map((value,j) => {
								if (value && q.keys[j] !== 'Ip') {
									return <p key={j}>{q.keys[j]}:
										<span>{value}</span>
									</p>
								} else {
									return null
								}
							})}
						</div>
					</div>
				</div>
			})}
		</div>
	)
}

Quotes.propTypes = {
	types: PropTypes.array,
	queries: PropTypes.array
}

export default () => (
	<Layout>
		<Quotes />
	</Layout>
)