import React from 'react';

export default (props) => {
  return (
    <a className='hibcb' href={props.url}>
      <span className='hibcb-block'>
        <i className={props.icon}></i>
      </span>
      <span className='hibcb-text'>{props.text}</span>
    </a>
  )
}