import React, { useState, useEffect } from 'react'
import {Helmet} from "react-helmet"
import { API_PATH, API_IMAGE_PATH } from './_Config'

const QuotePage = React.memo((props) => {

	const [ page, setPage ] = useState(null)
	const [ title, setTitle ] = useState('Request a Quote from Fleetwood Insurance Group')

	useEffect(() => {
		(async function() {
			const path = window.location.pathname === '/' ? '/home' : window.location.pathname
			try {
				const res = await fetch(API_PATH + path)
			  const json = await res.json()

			  setPage(json)
			  setTitle(json.title)
		  } catch(e) {
				setTimeout(() => {
					window.location.reload()
				},2000)
			}
		})()
	},[])

	const goBack = () => {
		window.history.back()
	}

	if (page) {
		return (
			<div className='page page-quote'>
				<div className='quote-header'>
					<div className='qh-container'>
						<img src={API_IMAGE_PATH + 'fleetwood-logo-300.png'} alt='Fleetwood Logo' />
						<i className="fal fa-times" onClick={goBack}></i>
					</div>
				</div>
				<Helmet>
	        <meta charSet="utf-8" />
	        <title>{title}</title>
	        <meta name='description' content='' />
	        <meta property='og:locale' content='en_US' />
	        <meta property='og:type' content='website' />
	        <meta property='og:title' content={title} />
	        <meta property='og:description' content='' />
	        <meta property='og:image' content='' />
	      </Helmet>
				{React.cloneElement(props.children, {...page})}
			</div>
		)
	} else return null
})

export default QuotePage