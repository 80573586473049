import React from 'react';

export default () => {
  return (
    <div className='hcrc-links'>
      <a href='tel:4107782260' className='phone'><span>Fleetwood Insurance:</span>410-778-2260</a>
      <a href='tel:4108222400' className='phone'><span>BGV Insurance:</span>410-822-2400</a>
      <a href='/support'>Customer Support</a>
      <a href='/about'>About Us</a>
      <a href='/contact'>Contact Us</a>
    </div>
  )
}