import React from 'react';
import Link from './HomeSectionLink'
import { API_IMAGE_PATH } from './_Config'

export default ({ content, scroll }) => (
  <div className='home-ib'>
    <div className='home-ib-container'>
      <div className='hibc-image' style={{backgroundImage: 'url(' + API_IMAGE_PATH + content[1].content + ')'}}>
        <div className='hibc-block hibc-block-right' style={{marginRight: scroll / 15 + 'px'}}>
          <h3>Insurance for Individuals</h3>
          <p>{content[2].content}</p>
          <a href='/personal'>Personal Insurance
            <i className='fas fa-long-arrow-alt-right'></i>
          </a>
        </div>
      </div>
      <div className='hibc-blocks'>
        <Link url='/personal' text='Motorcycle' icon='fas fa-motorcycle' />
        <Link url='/personal/auto' text='Auto' icon='fas fa-car' />
        <Link url='/personal/homeowners' text='Home' icon='fas fa-home' />
        <Link url='/personal/renters' text='Renters' icon='fas fa-key' />
        <Link url='/personal/rv' text='RV' icon='fas fa-shuttle-van' />
        <Link url='/personal' text='All Products' icon='fas fa-long-arrow-alt-right' />
      </div>
    </div>
  </div>
)