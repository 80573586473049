// import React from 'react'
// import { Redirect } from 'react-router';

import Home from '../Home'
import About from '../About'
import Locations from '../Locations'
import Staff from '../Staff'
import Community from '../Community'
import Companies from '../Companies'
import Contact from '../Contact'
import Blog from '../Blog'
import BlogPost from '../BlogPost'
import Events from '../Events'
import EventPost from '../EventPost'
import FourOFour from '../FourOFour'
import Insurance from '../Insurance'
import QuoteEmpty from '../Quote-Empty'
import Quote from '../Quote'
import Payments from '../Payments'
import CustomerSupport from '../Customer-Support'
import CustomerSupportRequests from '../Customer-Support-Requests'
import Legal from '../Legal'

import Admin from '../Admin'
import AdminSignin from '../Admin-Signin'
import AdminSignout from '../Admin-Signout'
import AdminList from '../Admin-List'
import AdminContent from '../Admin-Content'
import AdminNew from '../Admin-New'
import AdminDelete from '../Admin-Delete'
import AdminMedia from '../Admin-Media'
import AdminQueries from '../Admin-Queries'
import AdminResources from '../Admin-Resources'
import AdminAdmins from '../Admin-Admins'
import AdminQuotes from '../Admin-Quotes'
import AdminSupport from '../Admin-Support'

export default [
  {
    path: "/",
    exact: true,
    component: Home
  },
  {
    path: '/about/locations',
    component: Locations
  },
  {
    path: '/about/staff',
    component: Staff
  },
  {
    path: '/about/community',
    component: Community
  },
  {
    path: '/about/companies',
    component: Companies
  },
  {
    path: "/about",
    component: About
  },
  {
    path: "/contact",
    component: Contact
  },
  {
    path: "/blog/:id",
    component: BlogPost
  },
  {
    path: "/blog",
    component: Blog
  },
  {
    path: "/event/:id",
    component: EventPost
  },
  {
    path: "/events",
    component: Events
  },
  {
    path: ['/personal', '/business', '/marine', '/agriculture', '/high-net-worth'],
    component: Insurance,
    routes: [
      {
        path: "/:id",
        component: Insurance
      }
    ]
  },
  {
    path: '/quote',
    exact: true,
    component: QuoteEmpty
    //component: () => <Redirect to='/quote/auto/null/null' />
  },
  {
    path: '/quote/:type/:first/:last',
    component: Quote
  },
  {
    path: '/support',
    exact: true,
    component: CustomerSupport
  },
  {
    path: '/support/payments-and-claims',
    component: Payments
  },
  {
    path: '/support/r/:id',
    component: CustomerSupportRequests
  },
  {
    path: '/privacy-policy',
    component: Legal
  },

// =========================================
// Admin Routes
// =========================================

  {
    path: "/admin",
    exact: true,
    component: Admin
  },
  {
    path: "/admin/signin",
    component: AdminSignin
  },
  {
    path: "/admin/signout",
    component: AdminSignout
  },
  {
    path: ["/admin/pages", "/admin/events", "/admin/blog", "/admin/meta", "/admin/alerts"],
    exact: true,
    component: AdminList
  },
  {
    path: ["/admin/events/new", "/admin/blog/new", "/admin/alerts/new"],
    exact: true,
    component: AdminNew
  },
  {
    path: ["/admin/events/delete/:id", "/admin/blog/delete/:id", "/admin/alerts/delete/:id"],
    exact: true,
    component: AdminDelete
  },
  {
    path: ["/admin/pages/:id", "/admin/events/:id", "/admin/blog/:id", "/admin/meta/:id", "/admin/alerts/:id"],
    component: AdminContent
  },
  {
    path: "/admin/media",
    component: AdminMedia
  },
  {
    path: "/admin/queries",
    component: AdminQueries
  },
  {
    path: "/admin/quotes",
    component: AdminQuotes
  },
  {
    path: "/admin/support",
    component: AdminSupport
  },
  {
    path: "/admin/admins",
    component: AdminAdmins
  },
  {
    path: "/admin/resources",
    component: AdminResources
  },
  {
    path: "*",
    component: FourOFour
  }
]