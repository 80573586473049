import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet"
import { API_PATH } from './_Components/_Config'

import Head from './_Components/Head'
import Header from './_Components/Header'
import Footer from './_Components/Footer'

const Blog = React.memo(() => {

	const [ page, setPage ] = useState(null)

	useEffect(() => {
		(async function() {
			const res = await fetch(API_PATH + window.location.pathname)
			const json = await res.json()

			setPage(json)
			console.log(json)
		})()
	},[])

	return (
		<React.Fragment>
			{page
			? <div className='page page-blog-post'>
					<Helmet>
		        <meta charSet="utf-8" />
		        <title>{page.meta.title}</title>
		        <meta name='description' content={page.meta.description} />
		        <meta property='og:locale' content='en_US' />
		        <meta property='og:type' content='website' />
		        <meta property='og:title' content={page.meta.title} />
		        <meta property='og:description' content={page.meta.description} />
		        <meta property='og:image' content={'/static/media/' + page.meta.image} />
		      </Helmet>
		      <Head />
     			<Header />
					<div className='blog-post'>
						<div className='blog-post-date'>{page.date}</div>
						<div className='blog-post-content' dangerouslySetInnerHTML={{ __html: page.post[3].content }} />
					</div>
					<Footer />
				</div>
			: null}
		</React.Fragment>
	)
})

export default Blog