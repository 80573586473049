import React, { useState, useEffect, useCallback } from 'react';
import { API_IMAGE_PATH } from './_Config'

const Module = React.memo(({ scroll, slides }) => {
	
	const [ background, setBackground ] = useState(slides[0])
	const [ initial, setInitial ] = useState(true)

	const advanceSlides = useCallback((index, cancel = false) => {
		const advance = setTimeout(() => {
			setBackground(slides[index])
			if (index + 1 === slides.length) advanceSlides(0)
			else advanceSlides(index + 1)
		}, 6500)

		if (cancel) clearTimeout(advance)
	}, [slides])

	useEffect(() => {
		if (initial) {
			advanceSlides(1)
			setInitial(false)
		}
		return () => {
			advanceSlides(1, true)
		}
	}, [initial, advanceSlides])

	return (
		<div className='home-banner'>
			<div className='hb-background' style={{backgroundImage: 'url(' + API_IMAGE_PATH + background}}></div>
			<div className='hb-content'>
				<h1 style={{marginTop: scroll / 2 + 'px'}}>Enjoy life while we protect your future<span>Get insurance that works for you.</span></h1>
			</div>
			<div style={{display: 'none'}}>
				{slides.map((img, i) => {
					return <img key={i} src={API_IMAGE_PATH + img} alt={'picture of community in Eastern Shore, MD - ' + img}/>
				})}
			</div>
		</div>
	)
})

export default Module