import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

const Input = React.memo((props) => {

	useEffect(() => {
		if (props.inputName === '0_ip') {
			(async function getIp(url) {
				try {
			    const response = await fetch(url)
					const json = await response.json()
					const e = {
						target: {
							name: '0_ip',
							value: json.ip
						}
					}
					props.updateValue(e)
				} catch (error) {
					return null
				}
			})("https://jsonip.com")
		}
	},[props])

	return (
		<label htmlFor={props.inputName} style={{display: (props.display) || 'inline-block', width: (props.width) || '100%'}}>{props.name}
			{props.required &&
				<span className='required'>required</span>
			}
			<input
				type={props.type}
				name={props.inputName}
				required={props.required ? props.required : false}
				onChange={e => props.updateValue(e)}
				defaultValue={props.value ? props.value : ''}
			/>
		</label>
	)
})

Input.propTypes = {
	name: PropTypes.string.isRequired,
	inputName: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	updateValue: PropTypes.func,
	required: PropTypes.bool
}

export default Input