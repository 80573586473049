import Personal from './Personal.json'
import Vehicle from './Auto-Vehicle.json'
import Driver from './Auto-Driver.json'
import Coverage from './Auto-Coverage.json'
import PriorPolicy from './Auto-PriorPolicy.json'
import Additional from './Additional.json'
import Health from './Health.json'
import Business from './Business.json'

import Ag from './Agriculture.json'
import AgValues from './Agriculture-Values.json'
import AgCoverage from './Agriculture-Coverage.json'
import AgLosses from './Agriculture-Losses.json'

import Flood from './Flood.json'
import FloodCoverage from './Flood-Coverage.json'
import FloodLosses from './Flood-Losses.json'

import Home from './Homeowners.json'
import HomeCov from './Homeowners-Coverage.json'
import HomeLosses from './Homeowners-Losses.json'

import RentersCov from './Renters-Coverage.json'

import Truck from './Trucking.json'
import TruckOps from './Trucking-Operations.json'
import TruckAreas from './Trucking-Operations-Areas.json'
import TruckCom from './Trucking-Commodities.json'
import TruckLY from './Trucking-LY-Revenue.json'
import TruckTY from './Trucking-TY-Revenue.json'
import TruckNY from './Trucking-NY-Revenue.json'
import TruckEquip from './Trucking-Equipment.json'
import TruckPolicy from './Trucking-Policy.json'

// Workers Comp
import BusinessDetails from './Business-Details.json'
import Owner from './Owner.json'
import EmployeeGroup from './Employee-Group.json'
import BusinessMisc from './Business-Misc.json'
import BusinessCoverage from './Business-Coverage.json'

//================
//= Util for name formatting
//================

function nameToInputName(name) {
	const clean = name.replace(/[^a-zA-Z0-9 ]/g, '')
	var inputName = clean.replace(/ /gm, "_").toLowerCase()
	if (inputName.charAt(inputName.length) === '_') {
		inputName = inputName.slice(0, -1)
	}
	return inputName
}

//================
// Passing form data includes into data
//================

// const data = [
// 	Personal,
// 	Vehicle,
// 	Vehicle,
// 	Driver,
// 	Driver,
// 	Coverage,
// 	PriorPolicy,
// 	Additional
// ]

//================
// Setting initial variables
//================

const createObject = data => {
	var id = 0
	var view = []
	var state = []

	const setObject = (node, id) => {
		var temp = {
			type: node.type,
			name: node.name,
			required: node.required ? node.required : false,
			inputName: id + '_' + nameToInputName(node.name)
		}
		if (node.options) temp.options = node.options
		if (node.description) temp.description = node.description
		if (node.display) temp.display = node.display
		if (node.width) temp.width = node.width
		if (node.margin) temp.margin = node.margin
		if (node.text) temp.text = node.text
		if (node.checked !== undefined) temp.checked = node.checked
		return temp
	}

	data.forEach((section, i) => {
		let temp = []
		section.forEach((group, j) => {
			temp.push(setObject(data[i][j], id))
			state[id + '_' + nameToInputName(data[i][j].name)] = ''
			id++
		})
		view.push(temp)
	})
	return {view, state}
}

//================
// Setting returned object
//================

// const Form = {
// 	FormState: state,
// 	PersonalInputs: view[0],
// 	VehicleInputs: view[1],
// 	VehicleInputs2: view[2],
// 	DriverInputs: view[3],
// 	DriverInputs2: view[4],
// 	CoverageInputs: view[5],
// 	PriorPolicyInputs: view[6],
// 	AdditionalInputs: view[7]
// }

export default function(type) {
	if (type === 'Agriculture') {
		const data = [Ag, AgValues, AgCoverage, AgLosses, Additional]
		const res = createObject(data)
		return {
			FormState: res.state, AgInputs: res.view[0], AgValues: res.view[1], AgCoverage: res.view[2], AgLosses: res.view[3], AdditionalInputs: res.view[4]
		}
	} else if (type === 'Auto') {
		const data = [Personal,Vehicle,Vehicle,Driver,Driver,Coverage,PriorPolicy,Additional]
		const res = createObject(data)
		return {
			FormState: res.state,PersonalInputs: res.view[0],VehicleInputs: res.view[1],VehicleInputs2: res.view[2],DriverInputs: res.view[3],DriverInputs2: res.view[4],CoverageInputs: res.view[5],PriorPolicyInputs: res.view[6],AdditionalInputs: res.view[7]
		}
	} else if (type === 'Business') {
		const data = [Business,Additional]
		const res = createObject(data)
		return {
			FormState: res.state,BusinessInputs: res.view[0],AdditionalInputs: res.view[1]
		}
	} else if (type === 'Flood') {
		const data = [Flood, FloodCoverage, FloodLosses, Additional]
		const res = createObject(data)
		return {
			FormState: res.state, FloodInputs: res.view[0], FloodCoverage: res.view[1], FloodLosses: res.view[2], AdditionalInputs: res.view[3]
		}
	} else if (type === 'Health') {
		const data = [Personal,Health,Additional]
		const res = createObject(data)
		return {
			FormState: res.state,PersonalInputs: res.view[0],HealthInputs: res.view[1],AdditionalInputs: res.view[2]
		}
	} else if (type === 'Homeowners') {
		const data = [Home, HomeCov, HomeLosses, Additional]
		const res = createObject(data)
		return {
			FormState: res.state, HomeInputs: res.view[0], HomeCov: res.view[1], HomeLosses: res.view[2], AdditionalInputs: res.view[3]
		}
	} else if (type === 'Renters') {
		const data = [Home, RentersCov, HomeLosses, Additional]
		const res = createObject(data)
		return {
			FormState: res.state, HomeInputs: res.view[0], RentersCov: res.view[1], HomeLosses: res.view[2], AdditionalInputs: res.view[3]
		}
	} else if (type === 'Trucking') {
		const data = [Truck, TruckOps, TruckAreas, TruckCom, TruckLY, TruckTY, TruckNY, TruckEquip, TruckPolicy, Additional]
		const res = createObject(data)
		return {
			FormState: res.state, Truck: res.view[0], TruckOps: res.view[1], TruckAreas: res.view[2], TruckComm: res.view[3], TruckLY: res.view[4], TruckTY: res.view[5], TruckNY: res.view[6], TruckEquip: res.view[7], TruckPolicy: res.view[8], AdditionalInputs: res.view[9]
		}
	} else if (type === 'Workers Compensation') {
		const data = [Business,BusinessDetails,Owner,Owner,Owner,Owner,EmployeeGroup,EmployeeGroup,EmployeeGroup,EmployeeGroup,BusinessMisc,BusinessCoverage,Additional]
		const res = createObject(data)
		return {
			FormState: res.state, Business: res.view[0], Details: res.view[1], Owner1: res.view[2], Owner2: res.view[3], Owner3: res.view[4], Owner4: res.view[5], Group1: res.view[6], Group2: res.view[7], Group3: res.view[8], Group4: res.view[9], Misc: res.view[10], Coverage: res.view[11], AdditionalInputs: res.view[12]
		}
	} else {
		const data = [Personal,Additional]
		const res = createObject(data)
		return {
			FormState: res.state,PersonalInputs: res.view[0],AdditionalInputs: res.view[1]
		}
	}
}