import React from 'react'
import PropTypes from 'prop-types'

const Textarea = React.memo(props => {

	return (
		<label htmlFor={props.inputName} style={{display: (props.display) || 'inline-block', width: (props.width) || '100%'}}>{props.name}
			{props.required &&
				<span className='required'>required</span>
			}
			<textarea
				name={props.inputName}
				required={props.required ? props.required : false}
				onChange={e => props.updateValue(e)}
				defaultValue={props.value ? props.value : ''}
			/>
		</label>
	)
})

Textarea.propTypes = {
	name: PropTypes.string.isRequired,
	inputName: PropTypes.string.isRequired,
	defaultValue: PropTypes.string,
	updateValue: PropTypes.func,
	type: PropTypes.string,
	display: PropTypes.string,
	margin: PropTypes.string,
	required: PropTypes.bool,
	value: PropTypes.string
}

export default Textarea