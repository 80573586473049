import React from 'react'
import NavLogos from '../_Components/NavLogos'
import NavLinks from '../_Components/NavLinks'
import MobileNavToggle from '../_Components/MobileNavToggle'
import MobileNav from '../_Components/MobileNav'
import NavLinksTop from '../_Components/NavLinksTop'
import { API_IMAGE_PATH } from './_Config'

export default () => (
	<header>
		<div className='header-container' style={{backgroundImage: 'url(' + API_IMAGE_PATH + 'cream-pixels.png)'}}>
			<div className='hc-ribbon'>
				<div className='hcr-container'>
					<NavLinksTop />
					<MobileNavToggle />
				</div>
			</div>
			<NavLogos />
			<NavLinks />
			<MobileNav />
		</div>
	</header>
)